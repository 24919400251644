var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-unstyled"},[_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"font-weight-bold mr-3 font-size-18"},[_vm._v("16:00")]),_c('div',{staticClass:"bg-success mr-3",class:_vm.$style.separator}),_vm._m(0)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"font-weight-bold mr-3 font-size-18"},[_vm._v("15:28")]),_c('div',{staticClass:"bg-primary mr-3",class:_vm.$style.separator}),_vm._m(1)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"font-weight-bold mr-3 font-size-18"},[_vm._v("14:26")]),_c('div',{staticClass:"mr-3",class:_vm.$style.separator}),_vm._m(2)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"font-weight-bold mr-3 font-size-18"},[_vm._v("13:57")]),_c('div',{staticClass:"mr-3 bg-danger",class:_vm.$style.separator}),_vm._m(3)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"font-weight-bold mr-3 font-size-18"},[_vm._v("13:22")]),_c('div',{staticClass:"bg-info mr-3",class:_vm.$style.separator}),_vm._m(4)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"font-weight-bold mr-3 font-size-18"},[_vm._v("11:08")]),_c('div',{staticClass:"mr-3",class:_vm.$style.separator}),_vm._m(5)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"font-weight-bold mr-3 font-size-18"},[_vm._v("10:01")]),_c('div',{staticClass:"mr-3",class:_vm.$style.separator}),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("From themeforest")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("From themeforest")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("From Paypal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("From Bitcoin Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("From themeforest")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("From themeforest")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Payment Received")]),_c('div',{staticClass:"text-muted"},[_vm._v("From themeforest")])])
}]

export { render, staticRenderFns }