var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-uppercase font-size-12 mb-2 text-gray-6"},[_vm._v("Documents (3)")]),_c('ul',{staticClass:"list-unstyled"},[_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"mr-3",class:_vm.$style.itemPic},[_c('i',{staticClass:"fe fe-file",class:_vm.$style.itemIcon})]),_vm._m(0)])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"mr-3",class:_vm.$style.itemPic},[_c('i',{staticClass:"fe fe-file",class:_vm.$style.itemIcon})]),_vm._m(1)])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"mr-3",class:_vm.$style.itemPic},[_c('i',{staticClass:"fe fe-file",class:_vm.$style.itemIcon})]),_vm._m(2)])])]),_c('div',{staticClass:"text-uppercase font-size-12 mb-2 text-gray-6"},[_vm._v("Agents (4)")]),_c('ul',{staticClass:"list-unstyled"},[_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"mr-3",class:_vm.$style.avatar},[_c('img',{attrs:{"src":"resources/images/avatars/5.jpg","alt":"Mary Stanform"}})]),_vm._m(3)])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"mr-3",class:_vm.$style.avatar},[_c('img',{attrs:{"src":"resources/images/avatars/1.jpg","alt":"Jamie Rockstar"}})]),_vm._m(4)])]),_c('li',{class:_vm.$style.item},[_c('a',{class:_vm.$style.itemLink,attrs:{"href":"javascript: void(0);"}},[_c('div',{staticClass:"mr-3",class:_vm.$style.avatar},[_c('img',{attrs:{"src":"resources/images/avatars/4.jpg","alt":"Mary Stanform"}})]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-blue"},[_vm._v("System Requirements.pdf")]),_c('div',{staticClass:"text-muted"},[_vm._v("568kb")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-blue"},[_vm._v("Queue Info.pdf")]),_c('div',{staticClass:"text-muted"},[_vm._v("1.2mb")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-blue"},[_vm._v("Affected_app.mov")]),_c('div',{staticClass:"text-muted"},[_vm._v("67mb")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-blue"},[_vm._v("Mary Stanform")]),_c('div',{staticClass:"text-muted"},[_vm._v("Sales Manager")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-blue"},[_vm._v("Jamie Rockstar")]),_c('div',{staticClass:"text-muted"},[_vm._v("Blackoffice Agent")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-blue"},[_vm._v("David Bowie")]),_c('div',{staticClass:"text-muted"},[_vm._v("Blackoffice Agent")])])
}]

export { render, staticRenderFns }