var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"list-unstyled"},[_c('li',{class:_vm.$style.item},[_c('div',{class:_vm.$style.itemPic},[_c('i',[_vm._v("B")])]),_vm._m(0),_vm._m(1)]),_c('li',{class:_vm.$style.item},[_c('div',{class:_vm.$style.itemPic},[_c('i',[_vm._v("L")])]),_vm._m(2),_vm._m(3)]),_c('li',{class:_vm.$style.item},[_c('div',{class:_vm.$style.itemPic},[_c('i',[_vm._v("L")])]),_vm._m(4),_vm._m(5)]),_c('li',{class:_vm.$style.item},[_c('div',{class:_vm.$style.itemPic},[_c('i',[_vm._v("B")])]),_vm._m(6),_vm._m(7)]),_c('li',{class:_vm.$style.item},[_c('div',{class:_vm.$style.itemPic},[_c('i',[_vm._v("L")])]),_vm._m(8),_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Bitcoin")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Deposited")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-auto text-right text-nowrap"},[_c('div',[_vm._v("+0.005 BTC")]),_c('div',{staticClass:"text-success"},[_vm._v("+187.89$")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Litecoin")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Deposited by PayPal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-auto text-right text-nowrap"},[_c('div',[_vm._v("+2.3657 LTC")]),_c('div',{staticClass:"text-success"},[_vm._v("+187.89$")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Dash")])]),_c('div',{staticClass:"text-muted"},[_vm._v("To Dash adress")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-auto text-right text-nowrap"},[_c('div',[_vm._v("-2.3657 LTC")]),_c('div',{staticClass:"text-danger"},[_vm._v("-2187.89$")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Bitcoin")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Deposited")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-auto text-right text-nowrap"},[_c('div',[_vm._v("+0.005 BTC")]),_c('div',{staticClass:"text-success"},[_vm._v("+187.89$")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Litecoin")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Deposited by PayPal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-auto text-right text-nowrap"},[_c('div',[_vm._v("+2.3657 LTC")]),_c('div',{staticClass:"text-success"},[_vm._v("+187.89$")])])
}]

export { render, staticRenderFns }